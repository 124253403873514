import './index.scss';

interface RippletConfig {
  classes: string[];
}

const defaultConfig: RippletConfig = {
  classes: ['[data-ripplet]', '.y-btn', '.gw-button']
};

/**
 * Initializes the ripple effect by adding an event listener to the body
 * and setting up periodic ripple effects for elements with a specific data attribute.
 */
function initRipplet(config: RippletConfig = defaultConfig): void {
  document.body.addEventListener('pointerdown', (e) => handlePointerDownEvent(e, config), { passive: true });
  initializePeriodicRipples(config);
}

/**
 * Handle the pointerdown event to add a ripple effect.
 * @param e - The pointer event triggered on pointer down.
 * @param config - The ripple effect configuration.
 */
function handlePointerDownEvent(e: PointerEvent, config: RippletConfig): void {
  const targetElement = getTargetElement(e, config);
  if (targetElement && !targetElement.hasAttribute('data-no-ripple')) {
    applyRippleEffect(e, targetElement);
  }
}

/**
 * Returns the target element for the ripple effect.
 * @param e - The pointer event triggered on pointer down.
 * @param config - The ripple effect configuration.
 * @returns - The target element for the ripple effect or null.
 */
function getTargetElement(e: PointerEvent, config: RippletConfig): HTMLElement | null {
  for (const selector of config.classes) {
    const el = (e.target as Element).closest(selector) as HTMLElement;
    if (el && !isElementDisabled(el)) return el;
  }
  return null;
}

/**
 * Checks if the element is disabled.
 * @param element - The element to check.
 * @returns - True if the element is disabled, false otherwise.
 */
function isElementDisabled(element: HTMLElement): boolean {
  return element.hasAttribute('disabled') || element.classList.contains('disabled') || element.getAttribute('aria-disabled') === 'true';
}

/**
 * Apply the ripple effect to the target element.
 * @param e - The pointer event triggered on pointer down.
 * @param targetElement - The element to apply the ripple effect to.
 * @param isPeriodic - Whether the ripple is triggered periodically.
 */
function applyRippleEffect(e: PointerEvent, targetElement: HTMLElement, isPeriodic: boolean = false): void {
  const rect = targetElement.getBoundingClientRect();
  const diameter = calculateDiameter(rect.width, rect.height);
  if (isPeriodic) {
    targetElement.classList.add('periodic-ripple');
  } else {
    targetElement.classList.remove('periodic-ripple');
  }
  applyCSSVariables(targetElement, e.clientX - rect.left, e.clientY - rect.top, diameter);
}

/**
 * Calculates the diameter for the ripple effect.
 * @param width - The width of the target element.
 * @param height - The height of the target element.
 * @returns - The diameter for the ripple effect.
 */
function calculateDiameter(width: number, height: number): number {
  return Math.sqrt(width ** 2 + height ** 2) * 2;
}

/**
 * Applies CSS variables for the ripple effect to the target element.
 * @param element - The target element.
 * @param x - The x-coordinate of the event relative to the target element.
 * @param y - The y-coordinate of the event relative to the target element.
 * @param diameter - The diameter for the ripple effect.
 */
function applyCSSVariables(element: HTMLElement, x: number, y: number, diameter: number): void {
  element.style.cssText = `--s: 0; --o: 1;`;
  element.offsetTop; // Triggering reflow for CSS transition
  element.style.cssText = `--t: 1; --o: 0; --d: ${diameter}; --x:${x}; --y:${y};`;
}

/**
 * Initializes periodic ripple effects for elements with the data-ripplet-interval attribute.
 * @param config - The ripple effect configuration.
 */
function initializePeriodicRipples(config: RippletConfig): void {
  const elements = document.querySelectorAll<HTMLElement>('[data-ripplet-interval]');
  elements.forEach((element) => {
    const interval = parseInt(element.getAttribute('data-ripplet-interval') || '0', 10);
    if (interval > 0) {
      setInterval(() => {
        const rect = element.getBoundingClientRect();
        const event = new PointerEvent('pointerdown', {
          bubbles: true,
          clientX: rect.left + rect.width / 2,
          clientY: rect.top + rect.height / 2
        });
        element.dispatchEvent(event);
        applyRippleEffect(event, element, true);
      }, interval);
    }
  });
}

export { initRipplet };
